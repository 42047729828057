.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  text-decoration: none;
  color: black
}
.link:hover {
  text-decoration: underline;
  color: black

}

.row-header {
  padding: 10vh 10vw  0 10vw;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
}

.profile-pic {
  border-radius: 50%;
  width: 40%;
  max-width: 200px;
}

.header-col {
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 0 1px 10px 3px rgb(0 0 0 / 18%);
  bottom: -60px;
  padding: 30px 0 10px 0;
  max-width: 400px;
  margin: auto;
}

h1.name {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

h2.bio {
  font-weight: 400;
  font-size: 1.2em;
  padding: 14px;
  line-height: 1.5;
  margin: 0;
}

h3.bio {
  font-weight: 400;
  font-size: 1em;
  padding: 10px;
  padding-bottom: 5px;
  line-height: 1.5;
  margin: 0;
}

.about {
  display : flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
  width: 60%;
  margin-bottom: 20px;
}

.social-links img {
  height : 30px;
  width : 30px;
  margin: 5px; 
}

.social-links img:hover {
  -webkit-filter: brightness(70%);
	-moz-filter: brightness(70%);
	-o-filter: brightness(70%);
	-ms-filter: brightness(70%);
	filter: brightness(70%);
}

.projects {
  display : flex;
  font-size: 2em;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

}

.projectCard {
  display : flex;
  flex-direction: row;
  width : 60%;
  margin-bottom: 20px;
  padding: 15px;
  box-shadow: 5px 9px 9px #999;
  align-items: center;
  overflow: hidden;
  justify-content: space-evenly;
  border: 1px solid #EEE;
  border-radius: 10px;
}

.projectImage {
  border-radius: 10px;
  object-fit: contain;
  height: 300px;
}

.projectDetails {
  display: flex;
  margin:15px;
  width: 30%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.projectDetails h5 {
  font-weight: 500;
}

.projectDetails h6 {
  font-weight: 300;
}

.btn {
  background-color: #777;
  white-space: nowrap;
}

.aboutSite {
  font-size: 0.7em;
}

.icons {
  display : flex;
  width : 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:  center;
  margin : 15px;
}

.techIcon {
  margin : 5px;
  width : auto;
  height : 35px;
}

.links {
  display:flex;
  flex-direction: row;
}

.links a {
  font-size : 14px;
  margin : 5px;
}


@media screen and (min-width: 601px) and (max-width: 1180px){
  .projectCard {
    flex-direction: column;
    width : 80%;
    height:auto;
  }
  
  .imageContainer {
    height: auto;
  }
  
  .projectImage {
    max-width: 100%;
    height : 300px;
  }
  
  .projectDetails {
    justify-content: space-evenly;
    height:auto;
    width:auto;
  }
  
  .about {
    width : 90%
  }
}
@media screen and (max-width: 600px){
  .projectImage {
    max-width: 100%;
    height : auto;
  }

  .projectCard {
    flex-direction: column;
    width : 80%;
    height:auto;
  }
  
  .imageContainer {
    height: auto;
  }
  
  .projectDetails {
    justify-content: space-evenly;
    height:auto;
    width:auto;
  }
  
  .about {
    width : 90%
  }
}